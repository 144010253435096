import React, { useEffect } from "react";
import { Global_Data } from "../../globalData/GlobalData";

const RenderVarstore = ({ freeFormTabbleEditMainrecord, data }) => {

    const { setTextValue } = Global_Data?.() || {};

    useEffect(() => {
        if (data?.component?.sMode === "SET") {
            let valueToStore = data?.component?.sValue;

            if (valueToStore?.startsWith("{") && valueToStore?.endsWith("}")) {
                const variableName = valueToStore.slice(1, -1);
                valueToStore = freeFormTabbleEditMainrecord?.[variableName] ?? "";
            }

            if (data?.component?.sName) {
                localStorage.removeItem(data.component.sName);
                localStorage.setItem(data.component.sName, valueToStore);
                setTextValue((prev) => ({...prev, [data.component.sName]: valueToStore}))
            }
        } else if(data?.component?.sMode === "GET") {
            if (data?.component?.sName) {
                const value = localStorage.getItem(data.component.sName);
                setTextValue((prev) => ({...prev, [data.component.sName]: value}))
            }
        }
    }, [data, freeFormTabbleEditMainrecord]);

    return <></>;
};

export default RenderVarstore;
