import { vsprintf } from 'sprintf-js';

export function addLabels(data, series, formatting) {
    return series.map((item) => ({
        ...item,
        label: data?.component?.sLabels[item.dataKey],
        valueFormatter: (v) => {
            try {
                const match = formatting.match(/^([^\s%]*)/);
                const prefix = match ? match[1] : '';
                const formatWithoutPrefix = formatting.replace(prefix, '').trim();
                const formattedValue = vsprintf(String(v), formatWithoutPrefix);
                return `${prefix} ${Number(formattedValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            } catch (error) {
                console.error('Formatting error:', error, 'Value:', v, 'Formatting:', formatting);
                return;
            }
        },
    }));
}

export function replacePlaceholders(uri, data) {
    const regex = /{([^}]+)}/g;
    const replacedUri = uri?.replace(regex, (match, key) => {
        return key in (data || {}) ? data[key] : match;
    });
    return replacedUri;
}

export function hasEmptyQueryValue(apiURI) {
    try {
        const url = new URL(apiURI);
        const params = new URLSearchParams(url.search);
        for (const [key, value] of params) {
            if (value.trim() === '') {
                return true;
            }
        }
        return false;
    } catch (error) {
        console.error("Invalid API URI provided:", error.message);
        return false;
    }
}
