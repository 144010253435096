import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios';
import { serverAddress } from '../../config';
import { Global_Data } from '../../globalData/GlobalData';
import { hasEmptyQueryValue, replacePlaceholders } from './CommonFunctions';
import { PieChart } from '@mui/x-charts';
import { vsprintf } from 'sprintf-js';

const RenderPieChart = ({ data }) => {
    const [piechartDataResponse, setPiechartDataResponse] = useState([]);
    const { token, textValue } = Global_Data?.() || {};

    const dependencyFields = useMemo(() => {
        return data?.data?.sDataAware?.match(/{(.*?)}/g)?.map((field) => field.replace(/[{}]/g, "")) || [];
    }, [data?.data?.sDataAware]);

    const dependencies = useMemo(() => {
        return dependencyFields.map((field) => textValue?.[field] ?? null);
    }, [textValue, dependencyFields]);
    

    useEffect(() => {
        const sSeriesDataKeys = [];
        data?.component?.sSeries?.forEach((row) => {
            sSeriesDataKeys.push(row["data"]);
        });
        sSeriesDataKeys.forEach((series) => {
            const inputData = data["data"][series];
            if (!inputData?.bCascade) {
                axios.get(`${serverAddress}${inputData?.sDatasetSource}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(response => {
                    setPiechartDataResponse((prev) => ([...prev, {data: response?.data?.data?.records}]))
                }).catch(() => {
                    // setGlobalError(error);
                });
            } else {
                let processedURL = replacePlaceholders(inputData?.sDatasetSource, textValue);
                const apiURI = `${serverAddress}${processedURL}`;
                const emptyParams = hasEmptyQueryValue(apiURI);
                if(!emptyParams){
                    axios.get(apiURI, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }).then(response => {
                        setPiechartDataResponse((prev) => ([...prev, {data: response?.data?.data?.records}]))
                    }).catch(() => {
                        // setGlobalError(error);
                    });
                }                
            }
        })
    }, []);

    useEffect(() => {
        const sSeriesDataKeys = [];
        data?.component?.sSeries?.forEach((row) => {
            sSeriesDataKeys.push(row["data"]);
        });
        sSeriesDataKeys.forEach((series) => {
            const inputData = data["data"][series];
            if (inputData?.bCascade) {
                let processedURL = replacePlaceholders(inputData?.sDatasetSource, textValue);
                const apiURI = `${serverAddress}${processedURL}`;
                const emptyParams = hasEmptyQueryValue(apiURI);
                if(!emptyParams){
                    axios.get(apiURI, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }).then(response => {
                        setPiechartDataResponse((prev) => ([...prev, {data: response?.data?.data?.records}]))
                    }).catch(() => {
                        // setGlobalError(error);
                    });
                }           
            }
        })
    }, [dependencies]);

    function addLabels(data, series, seriesData, formatting, displayArc) {
        return {
            arcLabel: (item) => displayArc ? `${Number(item.value).toFixed(2)}` : "",
            ...series,
            data: seriesData?.map((item) => ({
                ...item,
                label: data?.component?.sLabels[item.label],
                value: item.value
            })),
            valueFormatter: (v) => {
                try {
                    const match = formatting.match(/^([^\s%]*)/);
                    const prefix = match ? match[1] : '';
                    const formatWithoutPrefix = formatting.replace(prefix, '').trim();
                    const formattedValue = vsprintf(String(v.value), formatWithoutPrefix);
                    return `${prefix} ${Number(formattedValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                } catch (error) {
                    console.error('Formatting error:', error, 'Value:', v, 'Formatting:', formatting);
                    return;
                }
            },
        };
    }
    return (
        <>
            {piechartDataResponse && piechartDataResponse.length > 0 &&
                <PieChart
                    id={data?.component?.sName}
                    series={piechartDataResponse?.map((entry, index) => (addLabels(data, data?.component?.sSeries[index], entry.data, data?.component?.sFormatting, data?.component?.bDisplayArc)))}
                    xAxis={[{ ...data?.component?.sXAxis }]}
                    yAxis={[{ ...data?.component?.sYAxis }]}
                    layout={data?.component?.sProps?.layout}
                    grid={data?.component?.sProps?.grid}
                    borderRadius={data?.component?.sProps?.borderRadius}
                    width={data?.component?.sProps?.width}
                    height={data?.component?.sProps?.height}
                    slotProps={data?.component?.sSlotProps}
                    margin={{ left: 70 }}
                />
            }
        </>
    )
}

export default RenderPieChart