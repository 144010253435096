import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Global_Data } from "../../globalData/GlobalData";

function SECTION({ data, renderComponent, textValue, varValue }) {
  const [isVisible, setIsVisible] = useState(false);
  const {setSectionComponentDataAware} = Global_Data?.() || {};
  useEffect(() => {
    if (
      data?.component?.options?.bCascade == "true" ||
      data?.component?.options?.bCascade == true
    ) {
      // alert(data.component.options.bCascade)

      if (
        textValue[
          data?.component?.options?.sVisibleDataAware?.replace("{", "")?.replace("}", "")
        ] == data?.component?.options?.sVisibleValue || varValue[
          data?.component?.options?.sVisibleDataAware?.replace("{", "")?.replace("}", "")
        ] == data?.component?.options?.sVisibleValue 
      ) {
        setIsVisible(true);
        // alert('jj')
      } else {
        setIsVisible(false);
      }
    } else {
      if (data?.component?.options?.bVisible == "true") {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  }, [
    data,
    textValue[data?.component?.options?.sVisibleDataAware?.replace("{", "")?.replace("}", "")],
    varValue[data?.component?.options?.sVisibleDataAware?.replace("{", "")?.replace("}", "")]
  ]);

  useEffect(() => {
    const key = data?.component?.options?.sVisibleDataAware?.replace("{", "")?.replace("}", "");
    const type = data?.child?.[0]?.component?.sType;
    const value = data?.child?.[0]?.component?.sName;
    if(key && value && type == "INPUTTABLE"){
      setSectionComponentDataAware({ [key]: value });
    }
  }, [])

  return (
    <>
      {/true/.test(isVisible) ? (
        <Grid item {...data?.grid_props}>
          <Box {...data?.component?.sProps} id={data?.component?.sName}>
            <Grid container {...data?.component?.options?.others1}>
              {renderComponent(data?.child)}
            </Grid>
          </Box>
        </Grid>
      ) : (
        data?.component?.options?.sVisibleValue == "!Yes" &&
        textValue[
          data?.component?.options?.sVisibleDataAware?.replace("{", "")?.replace("}", "")
        ] != "Yes" &&
        textValue[
          data?.component?.options?.sVisibleDataAware?.replace("{", "")?.replace("}", "")
        ] != "" && (
          <Grid item {...data?.grid_props}>
            <Box {...data?.component?.sProps} id={data?.component?.sName}>
              <Grid container {...data?.component?.options?.others1}>
                {renderComponent(data?.child)}
              </Grid>
            </Box>
          </Grid>
        )
      )}
    </>
  );
}

export default SECTION;
