import { LineChart } from '@mui/x-charts';
import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios';
import { serverAddress } from '../../config';
import { Global_Data } from '../../globalData/GlobalData';
import { addLabels, hasEmptyQueryValue, replacePlaceholders } from './CommonFunctions';

const RenderLineChart = ({ data }) => {
    const [linechartDataResponse, setLinechartDataResponse] = useState();
    const { token, textValue } = Global_Data?.() || {};

    const dependencyFields = useMemo(() => {
        return data?.data?.sDataAware?.match(/{(.*?)}/g)?.map((field) => field.replace(/[{}]/g, "")) || [];
    }, [data?.data?.sDataAware]);

    const dependencies = useMemo(() => {
        return dependencyFields.map((field) => textValue?.[field] ?? null);
    }, [textValue, dependencyFields]);

    useEffect(() => {
        if (!data?.data?.bCascade) {
            axios.get(`${serverAddress}${data?.data?.sDatasetSource}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                setLinechartDataResponse(response);
            }).catch(() => {
                // setGlobalError(error);
            });
        } else {
            let processedURL = replacePlaceholders(data?.data?.sDatasetSource, textValue);
            const apiURI = `${serverAddress}${processedURL}`;
            const emptyParams = hasEmptyQueryValue(apiURI);
            if(emptyParams){
                axios.get(`${serverAddress}${processedURL}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(response => {
                    setLinechartDataResponse(response);
                }).catch(() => {
                    // setGlobalError(error);
                });
            }
        }
    }, []);

    useEffect(() => {
        if (data?.data?.bCascade) {
            let processedURL = replacePlaceholders(data?.data?.sDatasetSource, textValue);
            const apiURI = `${serverAddress}${processedURL}`;
            const emptyParams = hasEmptyQueryValue(apiURI);
            if(!emptyParams){
                axios.get(`${serverAddress}${processedURL}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(response => {
                    setLinechartDataResponse(response);
                }).catch(() => {
                    // setGlobalError(error);
                });
            }
        }
    }, [dependencies]);


    return (
        <>
            {linechartDataResponse &&
                <LineChart
                    id={data?.component?.sName}
                    dataset={linechartDataResponse?.data?.data?.records}
                    series={addLabels(data, data?.component?.sSeries, data?.component?.sFormatting)}
                    xAxis={[{ ...data?.component?.sXAxis }]}
                    yAxis={[{ ...data?.component?.sYAxis }]}
                    layout={data?.component?.sProps?.layout}
                    grid={data?.component?.sProps?.grid}
                    borderRadius={data?.component?.sProps?.borderRadius}
                    width={data?.component?.sProps?.width}
                    height={data?.component?.sProps?.height}
                    slotProps={data?.component?.sSlotProps}
                    margin={{ left: 70 }}
                />
            }
        </>
    )
}

export default RenderLineChart;